import { SocialLogin } from '@capgo/capacitor-social-login';
import { useEffect } from 'react';

import { AppleOAuthProviderProps } from './types';
import { isDev, isStaging } from '@/config';
import { isIos } from '@/utils';

export const AppleOAuthProvider = ({ children }: AppleOAuthProviderProps) => {
  const getAppleClientId = () => {
    if (isDev) return 'com.refeeewise.app.dev.signin';
    if (isStaging) return 'com.refeeewise.app.staging.signin';

    return 'com.refeeewise.app.signin';
  };

  useEffect(() => {
    if (!isIos) return;

    SocialLogin.initialize({
      apple: {
        clientId: getAppleClientId(),
      },
    });
  }, []);

  return children;
};
