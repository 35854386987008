import { Link, Stack } from '@mui/material';
import { Text } from '@/components/elements';

import GooglePlayDownloadAppBadge from '@/assets/icons/googlePlayDownloadApp.svg';
import AppStoreDownloadAppBadge from '@/assets/icons/appleStoreDownloadApp.svg';
import MobileAppIllustration from '@/assets/illustrations/mobileApp.svg';

export const MobileAppReleaseSection = () => {
  return (
    <Stack
      pt={8}
      direction="row"
      justifyContent="center"
      alignItems="center"
      columnGap={8}
      rowGap={4}
      flexWrap={['wrap', 'nowrap']}
    >
      <MobileAppIllustration />
      <Stack spacing={4} alignItems="center">
        <Text translationKey="home:mobileAppIsAvailable" color="primary" variant="headlineMedium" align="center" />
        <Text translationKey="home:mobileAppIsAvailableMessage" align="center" sx={{ maxWidth: 270 }} />
        <Stack direction="row" spacing={8}>
          <Link href="https://play.google.com/store/apps/details?id=com.refereewise.app" target="_blank">
            <GooglePlayDownloadAppBadge />
          </Link>
          <Link href="https://apps.apple.com/us/app/refeereewise/id6736403214" target="_blank">
            <AppStoreDownloadAppBadge />
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};
