import { BackButtonListenerEvent, App as CapacitorApp } from '@capacitor/app';
import LanguageIcon from '@mui/icons-material/Language';
import LightModeIcon from '@mui/icons-material/LightMode';
import { Box, Button, Container, IconButton, Paper, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactNode, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SwipeBackPlugin } from '@/capacitorPlugins/SwipeBack';
import { Text } from '@/components/elements';
import { Logo } from '@/components/elements/Logo';
import { SafeAreaTopBox } from '@/components/layout';
import { useAppDispatch } from '@/hooks';
import { Pages, useGetPagePathWithLang } from '@/navigation';
import { toggleThemeMode as toggleThemeModeAction } from '@/styles/theme';
import { TranslationKeys, useAppTranslation } from '@/translation';
import { isWeb } from '@/utils';

import { LanguageSelector } from './LanguageSelector';

interface LoginPageTemplateProps {
  children: ReactNode;
  headerMessageKey?: TranslationKeys;
  closeAppOnBack?: boolean;
}

export const LoginPageTemplate = ({ children, headerMessageKey, closeAppOnBack }: LoginPageTemplateProps) => {
  const dispatch = useAppDispatch();
  const { palette } = useTheme();
  const { t } = useAppTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_searchParams, setSearchParams] = useSearchParams();

  const showCookiesConsent = () => {
    setSearchParams({ showCookiesSettings: 'true' });
  };

  const termsOfServicePath = useGetPagePathWithLang(Pages.termsOfService);
  const privacyPolicyPath = useGetPagePathWithLang(Pages.privacyPolicy);

  const [shouldOpenLangSel, setShouldOpenLangSel] = useState(false);

  const toggleThemeMode = () => dispatch(toggleThemeModeAction());

  useEffect(() => {
    if (isWeb) return;

    if (closeAppOnBack) SwipeBackPlugin.disable();
    else SwipeBackPlugin.enable();
  }, [closeAppOnBack]);

  useEffect(() => {
    const handleBackButton = ({ canGoBack }: BackButtonListenerEvent) => {
      if (closeAppOnBack) {
        CapacitorApp.exitApp();
        return;
      }

      if (canGoBack) window.history.back();
      else CapacitorApp.exitApp();
    };

    if (isWeb) return;

    CapacitorApp.addListener('backButton', handleBackButton);
  }, [closeAppOnBack]);

  return (
    <Container
      maxWidth="xs"
      sx={{
        px: 0,
        justifyContent: 'space-between',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        pt: 10,
      }}
    >
      <SafeAreaTopBox />
      <Box sx={{ justifyContent: 'center', display: 'flex' }}>
        <Logo linkToLandingPageOnWeb size="large" />
      </Box>

      <Box alignItems="center" justifyContent="center" display="flex" flexDirection="column">
        <Paper elevation={3} sx={{ p: [4, 6, 8], mb: 2, mt: 1, width: '100%', borderRadius: [0, 4] }}>
          {headerMessageKey && (
            <Box textAlign="center" mb={8}>
              <Text variant="headlineMedium" translationKey={headerMessageKey} />
            </Box>
          )}
          {children}
        </Paper>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            px: 2,
          }}
        >
          <Stack direction="row" ml={2} alignItems="center">
            <IconButton onClick={() => setShouldOpenLangSel(true)}>
              <LanguageIcon fontSize="small" />
            </IconButton>
            <LanguageSelector
              variant="standard"
              shouldOpen={shouldOpenLangSel}
              onOpen={() => setShouldOpenLangSel(false)}
            />
          </Stack>
          <IconButton
            onClick={toggleThemeMode}
            size="large"
            aria-label="toggle theme mode"
            sx={{ mr: 2, color: palette.app.outline }}
          >
            <LightModeIcon />
          </IconButton>
        </Box>
      </Box>

      <Stack spacing={2} alignItems="center" mb={5}>
        <Button variant="text" color="info" onClick={showCookiesConsent}>
          {t('common:cookiesFilesSettings')}
        </Button>
        <Stack direction="row" justifyContent="center" spacing={6}>
          <Button variant="text" color="info" href={termsOfServicePath} target="_blank">
            {t('common:applicationTermsAndConditions')}
          </Button>
          <Button variant="text" color="info" target="_blank" href={privacyPolicyPath}>
            {t('common:privacyPolicy')}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};
