export const googleTagManagerId = import.meta.env.VITE_GTM_ID;
export const sentryDsnWeb = import.meta.env.VITE_SENTRY_DSN_WEB;
export const sentryDsnCapacitor = import.meta.env.VITE_SENTRY_DSN_CAPACITOR;
export const apiUrl = import.meta.env.VITE_API_URL;
export const appEnvironment = import.meta.env.VITE_APP_ENVIRONMENT;
export const googleOAuthClientIdWeb = import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID_WEB;
export const googleOAuthClientIdAndroid = import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID_ANDROID;
export const googleOAuthClientIdIosDev = import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID_IOS_DEV;
export const googleOAuthClientIdIosStaging = import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID_IOS_STAGING;
export const googleOAuthClientIdIosProd = import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID_IOS_PROD;
export const stripeKey = import.meta.env.VITE_STRIPE_KEY;

export const isDev = appEnvironment === 'development';
export const isStaging = appEnvironment === 'staging';
export const isProd = appEnvironment === 'production';
