import { useGoogleLogin } from '@react-oauth/google';
import { useState } from 'react';

import { useAppDispatch, useNotify } from '@/hooks';
import { captureError, trackEvent } from '@/services';
import { useAppTranslation } from '@/translation';

import { ssoLogin } from '../../api';
import { setSession } from '../../store';

import { UseSSOLogin } from './types';

export const useSSOLogin: UseSSOLogin = () => {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation();
  const { notify } = useNotify();

  const [isLoading, setIsLoading] = useState(false);

  const catchLoginError = (error: unknown, type: 'google' | 'apple') => {
    notify('error', t('user:ssoLoginFailed'));
    captureError(new Error(`${type} SSO login failed:` + JSON.stringify(error)));
  };

  const loginUsingGoogleRequest = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      if (!access_token) {
        catchLoginError('No access token', 'google');
        setIsLoading(false);
        return;
      }

      const { accessToken, refreshToken } = await ssoLogin({
        type: 'google',
        payload: {
          token: access_token,
        },
      });
      trackEvent.user.login('google');
      dispatch(setSession({ accessToken, refreshToken, loginType: 'google' }));
      setIsLoading(false);
    },
    error_callback: (error) => {
      setIsLoading(false);
      if (error.type === 'popup_closed') return;

      catchLoginError(error, 'google');
    },
    onError: (error) => {
      setIsLoading(false);
      catchLoginError(error, 'google');
    },
  });

  const loginUsingGoogle = () => {
    setIsLoading(true);
    loginUsingGoogleRequest();
  };

  const loginUsingApple = async () => {
    setIsLoading(true);
    window.AppleID.auth
      .signIn()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then(async (response: any) => {
        const token = response?.authorization.code;

        if (!token) {
          catchLoginError('No access token', 'apple');
          setIsLoading(false);
          return;
        }

        const { accessToken, refreshToken } = await ssoLogin({
          type: 'apple',
          payload: {
            token: token,
          },
        });
        trackEvent.user.login('apple');
        dispatch(setSession({ accessToken, refreshToken, loginType: 'apple' }));
        setIsLoading(false);
      })
      .catch((error: unknown) => {
        setIsLoading(false);
        catchLoginError(error, 'apple');
      });
  };

  return {
    loginUsingGoogle,
    loginUsingApple,
    isLoading,
  };
};
