import { axiosInstance } from '@/services';

interface Payload {
  type: 'google' | 'facebook' | 'apple';
  payload: {
    token: string;
  };
}

interface SsoLoginResponse {
  access: string;
  refresh: string;
}

interface SsoLoginProcessedResponse {
  accessToken: string;
  refreshToken: string;
}

export const ssoLogin = async (data: Payload): Promise<SsoLoginProcessedResponse> => {
  const response = await axiosInstance.post<SsoLoginResponse>(
    `/dj-rest-auth/${data.type}/`,
    data.type === 'apple'
      ? {
          code: data.payload.token,
        }
      : {
          access_token: data.payload.token,
        },
    {
      headers: {
        Authorization: undefined,
      },
    },
  );

  return {
    accessToken: response.data.access,
    refreshToken: response.data.refresh,
  };
};
